import { DialogActions, IconButton, useMediaQuery } from '@mui/material';
import { theme } from 'core/theme';
import { LogoutOptions, useAuth0 } from '@auth0/auth0-react';

import ExitToAppIcon from '@mui/icons-material/ExitToApp';

import { IsTeamsTab } from '../../index';

const LogOutIconBtn = () => {
  const { logout } = useAuth0();

  const redirectTo: LogoutOptions = { logoutParams: { returnTo: window.location.origin } };

  const handleClick = () => {
    logout(redirectTo);
  };

  return (
    <IconButton onClick={handleClick}>
      <ExitToAppIcon
        style={{
          fontSize: '2.4rem',
          color: theme.palette.primary.main,
        }}
      />
    </IconButton>
  );
};

export const LogOutBtn = () => {
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return !IsTeamsTab() ? (
    !isMobile ? (
      <DialogActions
        sx={{
          backgroundColor: theme.palette.purple.lightPurple,
        }}
      >
        <LogOutIconBtn />
      </DialogActions>
    ) : (
      <LogOutIconBtn />
    )
  ) : null;
};
