import { ChangeEvent, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogContent,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import GirlSorry from 'assets/images/GirlSorry.png';
import { theme } from 'core/theme';
import { submitErrorMessage } from 'services/teamsErrorApi';
import { IResponseErrorForbidden } from 'core/interfaces/status';
import { useLocation } from 'react-router-dom';
import { LogOutBtn } from './LogOutBtn';

interface IErrorPayload {
  firstName: string | undefined;
  lastName: string | undefined;
  managerEmail: string;
  email?: string;
  company: string;
  message: string;
  reason?: string;
}
const ErrorCompanyNotKnown = () => {
  const location = useLocation();
  const errorData: IResponseErrorForbidden = location.state?.errorData;

  const [t] = useTranslation();
  const [isChecked, setIsChecked] = useState<boolean>(false);
  const [showError, setShowError] = useState(false);
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [isSubmitSuccess, setIsSubmitSuccess] = useState<boolean | null>(null);
  const [payload, setPayload] = useState<IErrorPayload>({
    firstName: errorData ? errorData.firstName : '',
    lastName: errorData ? errorData.lastName : '',
    email: errorData ? errorData.email : '',
    company: '',
    managerEmail: '',
    message: '',
    reason: 'Demande de renseignement',
  });
  const validateEmail = (managerEmail: string) => {
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    return emailRegex.test(managerEmail);
  };
  const isDisabled = payload.company !== '' && validateEmail(payload.managerEmail) && isChecked;
  const handleEmailChange = (e: ChangeEvent<HTMLInputElement>) => {
    const managerEmail = e.target.value;
    setPayload((prev) => ({ ...prev, managerEmail }));
  };
  const requestSupport = async () => {
    try {
      await submitErrorMessage(payload);
      setIsSubmitSuccess(true);
    } catch (error) {
      setIsSubmitSuccess(false);
      console.log(error);
    }
  };

  return !isMobile ? (
    <Dialog
      sx={{
        '& .MuiBackdrop-root': {
          backgroundColor: theme.palette.purple.backdropPurple,
          backdropFilter: 'blur(4px)',
        },
        '& .MuiDialogContent-root': { padding: '0' },
        '& .MuiDialog-paper': {
          borderRadius: '8px',
          '&::-webkit-scrollbar': {
            width: '5px',
            height: '5px',
            background: '#eeeeee',
            borderRadius: '50px',
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: '#532be3',
            borderRadius: '50px',
          },
        },
      }}
      open={true}
    >
      <LogOutBtn />
      <DialogContent>
        <Stack
          sx={{
            width: '360px',
            minHeight: '678px',
            maxHeight: '900px',
            padding: '24px 0px 12px 0px',
            borderRadius: '8px',
            backgroundColor: theme.palette.purple.lightPurple,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <img src={GirlSorry} style={{ padding: '5px 0px', width: '205px', height: '138px' }} />
          <Typography
            sx={{
              fontSize: '22px',
              fontWeight: '800',
              lineHeight: '28px',
              letterSpacing: '0em',
              textAlign: 'center',
              color: theme.palette.text.secondary,
            }}
          >
            <Trans>{t('INTEGRATION.TEAMS.DEMO_OR_ELSE')}</Trans>
          </Typography>
          <Typography
            sx={{
              fontSize: '16px',
              fontWeight: '500',
              lineHeight: '18px',
              letterSpacing: '0em',
              textAlign: 'center',
              padding: '16px',
              color: theme.palette.text.secondary,
            }}
          >
            <Trans>{t('INTEGRATION.TEAMS.DEMO_MESSAGE')}</Trans>
          </Typography>

          <Box
            sx={{
              width: '340px',
              borderRadius: '8px',
              backgroundColor: theme.palette.commonPalette.whiteFont,
              padding: '16px', // Add some padding for spacing
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                gap: '8px',
              }}
            >
              <Typography
                sx={{
                  fontSize: '12px',
                  fontWeight: '500',
                  lineHeight: '18px',
                  letterSpacing: '0em',
                  textAlign: 'center',
                  color: theme.palette.text.secondary,
                }}
              >
                {t('INTEGRATION.TEAMS.REVOKED_MESSAGE')}
              </Typography>
              <Box sx={{ display: 'flex', flexDirection: 'row', gap: '8px', width: '100%' }}>
                <TextField
                  label={t('INTEGRATION.TEAMS.LAST_NAME') + '*'}
                  placeholder={t('EDIT_PROFILE.FORM.LAST_NAME')}
                  variant="outlined"
                  size="medium"
                  InputLabelProps={{ style: { fontSize: '12px', paddingTop: '3px' } }}
                  disabled={errorData ? true : false}
                  value={errorData ? errorData.lastName : undefined}
                  onChange={(e) => {
                    setPayload((prev) => ({ ...prev, lastName: e.target.value }));
                  }}
                />
                <TextField
                  label={t('INTEGRATION.TEAMS.FIRST_NAME') + '*'}
                  placeholder={t('EDIT_PROFILE.FORM.FIRST_NAME')}
                  variant="outlined"
                  InputLabelProps={{ style: { fontSize: '12px', paddingTop: '3px' } }}
                  disabled={errorData ? true : false}
                  size="medium"
                  value={errorData ? errorData.firstName : undefined}
                  onChange={(e) => {
                    setPayload((prev) => ({ ...prev, firstName: e.target.value }));
                  }}
                />
              </Box>
              <TextField
                label={t('ACROSS_COMPANIES.ACCOUNTING.COMPANY') + '*'}
                variant="outlined"
                size="medium"
                InputLabelProps={{ style: { fontSize: '12px', paddingTop: '3px' } }}
                placeholder={t('ACROSS_COMPANIES.ACCOUNTING.COMPANY')}
                sx={{ width: '100%' }}
                onChange={(e) => {
                  setPayload((prev) => ({ ...prev, company: e.target.value }));
                }}
              />
              <TextField
                label={t('INTEGRATION.TEAMS.MANAGER_EMAIL') + '*'}
                placeholder={t('INTEGRATION.TEAMS.MANAGER_EMAIL')}
                variant="outlined"
                size="medium"
                InputLabelProps={{ style: { fontSize: '12px', paddingTop: '3px' } }}
                value={payload.managerEmail === '' ? undefined : payload.managerEmail}
                sx={{ width: '100%' }}
                error={showError}
                helperText={showError ? t('INTEGRATION.TEAMS.INVALID_EMAIL') : ''}
                onChange={handleEmailChange}
                onFocus={() => setShowError(true)}
                onBlur={() => setShowError(false)}
              />
              <TextField
                label={t('INTEGRATION.TEAMS.DISCOVER_US')}
                variant="outlined"
                size="medium"
                placeholder={t('INTEGRATION.TEAMS.DISCOVER_US')}
                multiline
                InputLabelProps={{ style: { fontSize: '12px', paddingTop: '3px' } }}
                sx={{ width: '100%' }}
                onChange={(e) => {
                  setPayload((prev) => ({ ...prev, message: e.target.value }));
                }}
                rows={3}
              />
            </Box>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
                paddingBottom: '12px',
              }}
            >
              <Checkbox onChange={() => setIsChecked((current) => !current)} />
              <Typography
                sx={{
                  fontSize: '12px',
                  fontWeight: '500',
                  lineHeight: '14px',
                  letterSpacing: '0em',
                  color: theme.palette.text.secondary,
                }}
              >
                {t('INTEGRATION.TEAMS.CONSENT_DATA')}
              </Typography>
            </Box>
            <Button
              onClick={requestSupport}
              disabled={!isDisabled}
              sx={{
                width: '192px',
                height: '36px',
                borderRadius: '12px',
                backgroundColor: !isDisabled
                  ? theme.palette.primary.light
                  : theme.palette.primary.main,

                color: theme.palette.commonPalette.whiteFont,
                ':hover': {
                  backgroundColor: !isDisabled
                    ? theme.palette.primary.dark
                    : theme.palette.primary.light,
                  color: theme.palette.commonPalette.whiteFont,
                },
              }}
            >
              {t('INTEGRATION.TEAMS.ENVOYER')}
            </Button>
            {isSubmitSuccess !== null && (
              <Typography
                sx={{
                  marginTop: '12px',
                  fontSize: '12px',
                  fontWeight: '500',
                  lineHeight: '18px',
                  letterSpacing: '0em',
                  textAlign: 'center',
                  color: isSubmitSuccess ? theme.palette.success.dark : theme.palette.error.dark,
                }}
              >
                {isSubmitSuccess
                  ? t('INTEGRATION.TEAMS.SUBMIT_SUCCESS')
                  : t('INTEGRATION.TEAMS.SUBMIT_FAIL')}
              </Typography>
            )}
          </Box>
        </Stack>
      </DialogContent>
    </Dialog>
  ) : (
    <Stack
      sx={{
        width: '100vw',
        minHeight: '678px',
        maxHeight: '900px',
        padding: '24px 0px 12px 0px',
        borderRadius: '8px',
        backgroundColor: theme.palette.purple.lightPurple,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Box
        sx={{
          width: '100%',
          justifyContent: 'flex-end',
          display: 'flex',
        }}
      >
        <LogOutBtn />
      </Box>
      <img src={GirlSorry} style={{ padding: '5px 0px', width: '205px', height: '138px' }} />
      <Typography
        sx={{
          fontSize: '22px',
          fontWeight: '800',
          lineHeight: '28px',
          letterSpacing: '0em',
          textAlign: 'center',
          color: theme.palette.text.secondary,
        }}
      >
        {t('INTEGRATION.TEAMS.DEMO_OR_ELSE')}
      </Typography>
      <Typography
        sx={{
          fontSize: '16px',
          fontWeight: '500',
          lineHeight: '18px',
          letterSpacing: '0em',
          textAlign: 'center',
          padding: '16px',
          color: theme.palette.text.secondary,
        }}
      >
        <Trans>{t('INTEGRATION.TEAMS.DEMO_MESSAGE')}</Trans>
      </Typography>

      <Box
        sx={{
          width: '340px',
          borderRadius: '8px',
          backgroundColor: theme.palette.commonPalette.whiteFont,
          padding: '16px', // Add some padding for spacing
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '8px',
          }}
        >
          <Typography
            sx={{
              fontSize: '12px',
              fontWeight: '500',
              lineHeight: '18px',
              letterSpacing: '0em',
              textAlign: 'center',
              color: theme.palette.text.secondary,
            }}
          >
            {t('INTEGRATION.TEAMS.REVOKED_MESSAGE')}
          </Typography>
          <Box sx={{ display: 'flex', flexDirection: 'row', gap: '8px', width: '100%' }}>
            <TextField
              label={t('INTEGRATION.TEAMS.LAST_NAME') + '*'}
              placeholder={t('EDIT_PROFILE.FORM.LAST_NAME')}
              variant="outlined"
              size="medium"
              InputLabelProps={{ style: { fontSize: '12px', paddingTop: '3px' } }}
              disabled={errorData ? true : false}
              value={errorData ? errorData.lastName : undefined}
              onChange={(e) => {
                setPayload((prev) => ({ ...prev, lastName: e.target.value }));
              }}
            />
            <TextField
              label={t('INTEGRATION.TEAMS.FIRST_NAME') + '*'}
              placeholder={t('EDIT_PROFILE.FORM.FIRST_NAME')}
              variant="outlined"
              InputLabelProps={{ style: { fontSize: '12px', paddingTop: '3px' } }}
              disabled={errorData ? true : false}
              size="medium"
              value={errorData ? errorData.firstName : undefined}
              onChange={(e) => {
                setPayload((prev) => ({ ...prev, firstName: e.target.value }));
              }}
            />
          </Box>
          <TextField
            label={t('ACROSS_COMPANIES.ACCOUNTING.COMPANY') + '*'}
            variant="outlined"
            size="medium"
            InputLabelProps={{ style: { fontSize: '12px', paddingTop: '3px' } }}
            placeholder={t('ACROSS_COMPANIES.ACCOUNTING.COMPANY')}
            sx={{ width: '100%' }}
            onChange={(e) => {
              setPayload((prev) => ({ ...prev, company: e.target.value }));
            }}
          />
          <TextField
            label={t('INTEGRATION.TEAMS.MANAGER_EMAIL') + '*'}
            placeholder={t('INTEGRATION.TEAMS.MANAGER_EMAIL')}
            variant="outlined"
            size="medium"
            InputLabelProps={{ style: { fontSize: '12px', paddingTop: '3px' } }}
            value={payload.managerEmail === '' ? undefined : payload.managerEmail}
            sx={{ width: '100%' }}
            error={showError}
            helperText={showError ? t('INTEGRATION.TEAMS.INVALID_EMAIL') : ''}
            onChange={handleEmailChange}
            onFocus={() => setShowError(true)}
            onBlur={() => setShowError(false)}
          />
          <TextField
            label={t('INTEGRATION.TEAMS.DISCOVER_US')}
            variant="outlined"
            size="medium"
            placeholder={t('INTEGRATION.TEAMS.DISCOVER_US')}
            multiline
            InputLabelProps={{ style: { fontSize: '12px', paddingTop: '3px' } }}
            sx={{ width: '100%' }}
            onChange={(e) => {
              setPayload((prev) => ({ ...prev, message: e.target.value }));
            }}
            rows={3}
          />
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            paddingBottom: '12px',
          }}
        >
          <Checkbox onChange={() => setIsChecked((current) => !current)} />
          <Typography
            sx={{
              fontSize: '12px',
              fontWeight: '500',
              lineHeight: '14px',
              letterSpacing: '0em',
              color: theme.palette.text.secondary,
            }}
          >
            {t('INTEGRATION.TEAMS.CONSENT_DATA')}
          </Typography>
        </Box>
        <Button
          onClick={requestSupport}
          disabled={!isDisabled}
          sx={{
            width: '192px',
            height: '36px',
            borderRadius: '12px',
            backgroundColor: !isDisabled ? theme.palette.primary.light : theme.palette.primary.main,
            color: theme.palette.commonPalette.whiteFont,
            ':hover': {
              backgroundColor: !isDisabled
                ? theme.palette.primary.dark
                : theme.palette.primary.light,
              color: theme.palette.commonPalette.whiteFont,
            },
          }}
        >
          {t('INTEGRATION.TEAMS.ENVOYER')}
        </Button>
        {isSubmitSuccess !== null && (
          <Typography
            sx={{
              marginTop: '12px',
              fontSize: '12px',
              fontWeight: '500',
              lineHeight: '18px',
              letterSpacing: '0em',
              textAlign: 'center',
              color: isSubmitSuccess ? theme.palette.success.dark : theme.palette.error.dark,
            }}
          >
            {isSubmitSuccess
              ? t('INTEGRATION.TEAMS.SUBMIT_SUCCESS')
              : t('INTEGRATION.TEAMS.SUBMIT_FAIL')}
          </Typography>
        )}
      </Box>
    </Stack>
  );
};

export default ErrorCompanyNotKnown;
